import { Box, Button, Grid, Typography, Link,  } from "@mui/material";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

function Login({ onLogin }) {
  const navigate = useNavigate();
  const [isRegistering, setIsRegistering] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      const response = await fetch("http://localhost:5000/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error("Invalid credentials");
      }

      const data = await response.json();
      localStorage.setItem("token", data.token);
      onLogin();
      navigate("/"); // Redirect to the home page after login
    } catch (err) {
      setError(err.message);
    }
  };

  const handleRegister = async () => {
    try {
      const response = await fetch("http://localhost:5000/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          username,
          password,
        }),
      });

      if (!response.ok) {
        throw new Error("Registration failed");
      }

      const data = await response.json();
      localStorage.setItem("token", data.token); // Log in the user automatically after registration
      onLogin();
      navigate("/"); // Redirect to home after registration
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "500px" },
          border: "1px solid #ccc",
          borderRadius: 0, // Set border radius to 0
          padding: "20px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h4" color="black" sx={{ marginBottom: 2 }}>
          {isRegistering ? "Register" : "Login"}
        </Typography>
        {error && <Typography color="error">{error}</Typography>}

        {isRegistering ? (
          <>
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              Your Personal Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                  First Name
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item lg={6}>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                  Last Name
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                  Email
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              Username
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              sx={{ marginBottom: 2 }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              Password
            </Typography>
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              sx={{ marginBottom: 2 }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            backgroundColor: "#febd2f",
            color: "#000",
            padding: "10px 20px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: 0, // Set border radius to 0
            "&:hover": {
              backgroundColor: "black",
              color: "white",
              boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
            },
          }}
          onClick={isRegistering ? handleRegister : handleLogin}
        >
          {isRegistering ? "Register" : "Login"}
        </Button>

        <Grid item xs={12} sx={{ textAlign: "center", marginTop: 2 }}>
          <Link
            onClick={() => {
              setIsRegistering(!isRegistering);
              // Clear fields when switching forms
              if (isRegistering) {
                setFirstName("");
                setLastName("");
                setEmail("");
              }
              setUsername("");
              setPassword("");
            }}
            variant="body2"
            color="primary"
            sx={{ cursor: "pointer" }}
          >
            {isRegistering
              ? "Already have an account? Login"
              : "Don't have an account? Register"}
          </Link>
        </Grid>
        {!isRegistering && (
          <Grid item xs={12} sx={{ textAlign: "center", marginTop: 1 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setIsRegistering(true)}
              sx={{ borderRadius: 0 }}
            >
              Create an Account
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sx={{ textAlign: "center", marginTop: 1 }}>
          <Link to="/forgot-password" variant="body2" color="primary">
            Forgot Password?
          </Link>
        </Grid>
      </Box>
    </Box>
  );
}

export default Login;
