import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Favorite as HeartIcon,
  Visibility as EyeIcon,
} from "@mui/icons-material";
import { SelectChangeEvent } from "@mui/material/Select";
import { Close as CloseIcon } from "@mui/icons-material"; // Import the Close Icon from Material UI
import { useNavigate } from "react-router-dom"; // Import useNavigate

const CardComponent = ({
  image,
  title,
  description,
  price,
  onAddToCartClick,
  isLastCard,
}) => {
  const [quantity, setQuantity] = useState("");
  const [openModal, setOpenModal] = useState(false); // State for modal visibility
  const navigate = useNavigate(); // Initialize the navigation hook
  const [openSnackbar, setOpenSnackbar] = useState(false); // State to open/close the Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // State for the Snackbar message

  
  
  // Function to handle the button click and show the Snackbar
  const handleViewCartClick = () => {
    navigate("/cart");  // Navigate to the cart page
    // Trigger the onAddToCartClick function (which adds the product to the cart)
    onAddToCartClick();

    // Set the Snackbar message and open the Snackbar
    setSnackbarMessage("Item added to your cart!");
    setOpenSnackbar(true);

    // Optionally, you can add a timeout to automatically close the Snackbar after 3 seconds
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 3000); // Close after 3 seconds
  };
  

  // Function to handle adding product to wishlist
  const handleWishlistClick = () => {
    // Create a product object to add to the wishlist
    const product = { image, title, description, price };

    // Get existing wishlist from localStorage
    let wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];

    // Check if the product is already in the wishlist
    const isProductInWishlist = wishlist.some(
      (item) => item.title === product.title
    );

    if (!isProductInWishlist) {
      // If not in wishlist, add the product
      wishlist.push(product);
      localStorage.setItem("wishlist", JSON.stringify(wishlist)); // Save updated wishlist to localStorage
    }

    // Navigate to the Wishlist page
    navigate("/wishlist");
  };

  const handleQuantityChange = (event: SelectChangeEvent) => {
    setQuantity(event.target.value);
  };

  const handleOpenModal = () => {
    setOpenModal(true); // Open the modal when "Eye" is clicked
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  };

  return (
    <>
      {/* Product Card */}
      <Card
        sx={{
          display: "flex",
          marginBottom: "10px",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #febd2f",
          borderRadius: "0",
          position: "relative",
          overflow: "hidden",
          maxWidth: 700,
          height: "300px",
          flex: 1,
          borderRight: isLastCard ? "1px solid #febd2f" : "none",
          "&:hover": {
            ".hover-icons": {
              opacity: 1,
            },
          },
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: 180,
            height: "100%",
            objectFit: "contain",
            pl: "10px",
          }}
          image={image}
          alt={title}
        />
        <Box sx={{ display: "flex", flexDirection: "column", p: 3 }}>
          <CardContent sx={{ flex: 1, color: "#febd2f" }}>
            <Typography gutterBottom variant="body2" component="div">
              {title}
            </Typography>
            <Typography variant="h5" color="text.secondary">
              {description}
            </Typography>
            <Typography variant="h6" sx={{ mt: 1, fontWeight: "bold" }}>
              {price}
            </Typography>
          </CardContent>

          <Box sx={{ mb: 2.5 }}>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="quantity-select-label">Qty</InputLabel>
              <Select
                labelId="quantity-select-label"
                id="quantity-select"
                value={quantity}
                label="Qty"
                onChange={handleQuantityChange}
                sx={{ borderRadius: "30px" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              mb: 2.5,
              display: "flex",
              alignItems: "center",
              paddingBottom: "100px",
              pr: 5,
              gap: 2,
            }}
          >
            {/* View Cart Button */}
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleViewCartClick}
              sx={{
                minWidth: 165,
                backgroundColor: "#febd2f",
                color: "#173334",
                padding: "10px 20px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "0",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                  boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
                },
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              View Cart
            </Button>

            {/* Snackbar to show the notification */}
            <Snackbar
              open={openSnackbar}
              autoHideDuration={3000} // Close after 3 seconds
              onClose={() => setOpenSnackbar(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position at the top-center
            >
              <Alert
                onClose={() => setOpenSnackbar(false)}
                severity="success"
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>

          {/* Hover Icons */}
          <Box
            className="hover-icons"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              display: "flex",
              flexDirection: "row",
              gap: 1,
              p: 1,
              opacity: 0,
              transition: "opacity 0.3s ease",
            }}
          >
            <Tooltip title="Add to Favorites">
              <IconButton
                sx={{ color: "#febd2f" }}
                onClick={handleWishlistClick}
              >
                <HeartIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Details">
              <IconButton sx={{ color: "#febd2f" }} onClick={handleOpenModal}>
                <EyeIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Card>

      {/* Product Details Modal */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#173334", // Apply background color directly to the dialog paper (the modal container)
            color: "#febd2f", // Update text color to improve contrast with the background
          },
        }}
      >
        <DialogTitle sx={{ color: "#febd2f" }}>{title}</DialogTitle>
        <DialogContent sx={{ display: "flex", gap: 3, alignItems: "center" }}>
          <CardMedia
            component="img"
            sx={{
              width: 200,
              height: 200,
              objectFit: "contain",
            }}
            image={image}
            alt={title}
          />
          <Box>
            <Typography variant="h6" sx={{ color: "#febd2f" }}>
              {description}
            </Typography>
            <Typography variant="h5" sx={{ mt: 2, color: "#febd2f" }}>
              {price}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={onAddToCartClick}
                sx={{
                  minWidth: 165,
                  backgroundColor: "#febd2f",
                  color: "#173334",
                  padding: "10px 20px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "0",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                    boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
                  },
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                View Cart
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            sx={{
              backgroundColor: "#febd2f",
              color: "#173334",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "0",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
                boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
              },
              display: "flex",
              alignItems: "center",
              gap: "8px", // Keep a gap between the text and icon
            }}
          >
            <CloseIcon sx={{ color: "#173334" }} /> {/* Add the Close icon */}
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CardComponent;
