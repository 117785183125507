import React, { useState } from "react";
import Navbar from "../Navbar/Navbar"; // Adjust the import path if necessary
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import BrushIcon from "@mui/icons-material/Brush";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Delivery from "../GoceryDelivery/Delivery";
import BestSellerCard from "../Card/BestSellerCard";
import MessageComponent from "./Popup/Popup";
import { Link } from "react-router-dom";
// import AgriSlider from "../Slider/AgriSlider";

const texts = [
  {
    heading: "Frozen",
    description:
      "Frozen foods are a convenient and nutritious way to incorporate healthy foods into your diet.",
    image:
      "https://static.toiimg.com/thumb/msid-111575935,width-1280,height-720,resizemode-4/111575935.jpg",
  },
  {
    heading: "Organic & Fresh Produce",
    description:
      "Organic food is grown without the use of synthetic chemicals, such as human-made pesticides and fertilizers, and does not contain genetically modified organisms (GMOs).",
    image:
      "https://healthybuddha.in/image/catalog/Recentblogs/blogs/fresh-vegetables-food.jpg",
  },
  {
    heading: "Seasonal Fruits & Vegetables",
    description:
      "Enjoy seasonal fruits and vegetables, picked fresh from the farm. Our grocery delivery service ensou get the best produce every time.",
    image:
      "https://cultdesi.com/cdn/shop/articles/Fruits_and_veg_520x500_3a149d06-c77c-46e2-aa1c-aa81f201d928_800x.png?v=1644047374",
  },
];

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const handlePrevClick = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? texts.length - 1 : prevIndex - 1
      );
      setFade(true);
    }, 300);
  };

  const handleNextClick = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === texts.length - 1 ? 0 : prevIndex + 1
      );
      setFade(true);
    }, 300);
  };

  return (
    <>
      <Box
        className="fade-in"
        sx={{
          backgroundColor: "#173334",
          minHeight: "115vh",
          color: "#febd2f",
          overflow: "hidden",
          animation: "fadeIn 1s ease-in-out", // Fade-in effect
          "@keyframes fadeIn": {
            "0%": { opacity: 0, transform: "translateY(20px)" },
            "100%": { opacity: 1, transform: "translateY(0)" },
          },
        }}
      >
        <Navbar />
        <Divider
          orientation="horizontal"
          sx={{
            borderColor: "#febd2f",
            paddingTop: "5.7rem",
            width: "100%",
            backgroundColor: "#febd2f",
          }}
        />

        <Box sx={{ pt: 8 }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "0 auto",
              padding: "0 10px",
              maxWidth: "1170px",
            }}
          >
            <Grid item xs={12} md={6} lg={6}>
              <Box
                sx={{
                  transition: "opacity 0.4s ease-in-out",
                  opacity: fade ? 1 : 0,
                  animation: "fadeIn 1s ease-in-out forwards",
                  "@keyframes fadeIn": {
                    "0%": { opacity: 0 },
                    "100%": { opacity: 1 },
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    lineHeight: "1.5rem",
                    marginBottom: "1rem",
                    fontSize: "1rem",
                  }}
                >
                  TheAgriGoods
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    lineHeight: "1.2",
                    fontWeight: 700,
                    marginBottom: "1rem",
                    fontSize: "3rem",
                    animation: "fadeIn 1s ease-in-out forwards",
                    animationDelay: "0.5s", // Staggered appearance
                    opacity: 0,
                    "@keyframes fadeIn": {
                      "0%": { opacity: 0 },
                      "100%": { opacity: 1 },
                    },
                  }}
                >
                  <strong style={{ marginBottom: "3rem" }}>
                    {texts[currentIndex].heading.split(" ")[0]}
                  </strong>
                  <br /> {texts[currentIndex].heading.split(" ")[4]}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    lineHeight: "1.6rem",
                    color: "#f1f1f1",
                    animation: "fadeIn 1s ease-in-out forwards",
                    animationDelay: "1s",
                    opacity: 0,
                  }}
                >
                  {texts[currentIndex].description}
                </Typography>
              </Box>
              <Box
                sx={{
                  top: "25rem",
                  paddingTop: "2rem",
                  display: "flex",
                  justifyContent: "space-between", // Changed to space-between for better alignment
                  alignItems: "center",
                  position: "absolute",
                  bottom: "1%",
                  left: "7rem",
                  transform: "translateY(-50%)",
                  gap: "10px",
                  flexDirection: { xs: "row", sm: "row",top:"32rem",left:"7rem", }, // Stack vertically on small screens
                  width: { xs: "90%", sm: "auto" }, // Make width responsive
                }}
              >
                <Button
                  as={Link} to="/categories"
                  sx={{
                    backgroundColor: "#febd2f",
                    textDecoration:"none",
                    color: "#173334",
                    padding: "10px 20px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "0",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                      boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
                    },
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    width: { xs: "100%", sm: "auto" }, // Full width on small screens
                  }}
                >
                  <BrushIcon /> Show Products
                </Button>
                <Button
                 as={Link} to="/basmati"
                  sx={{
                    backgroundColor: "#febd2f",
                    color: "#173334",
                    padding: "10px 20px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "0",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                      boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
                    },
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    width: { xs: "100%", sm: "auto" }, // Full width on small screens
                  }}
                >
                  <SearchIcon /> Find Products
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} mb={5}>
              <img
                className="zoom-in"
                src={texts[currentIndex].image}
                alt="Main Banner"
                style={{
                  width: "100%",
                  height: "100%",
                  
                  animation: "zoomIn 1s ease-in-out", // Zoom-in effect
                  "@keyframes zoomIn": {
                    "0%": { transform: "scale(0.8)", opacity: 0 },
                    "100%": { transform: "scale(1)", opacity: 1 },
                  },
                }}
              />
            </Grid>
          </Grid>

          {/* Arrow Buttons - Fixed Position */}
          <Box
            sx={{
              position: "absolute",
              top: "38rem",
              left: "6rem",
              transform: "translateY(-50%)",
              display: "flex",
              flexWrap: "nowrap",
              gap: "10px",
            }}
          >
            <Button onClick={handlePrevClick} className="button-hover">
              <KeyboardDoubleArrowLeftIcon
                sx={{
                  color: "#febd2f",
                  lineHeight: "1.5rem",
                  fontSize: "2.5rem",
                }}
              />
            </Button>
            <Button onClick={handleNextClick} className="button-hover">
              <KeyboardDoubleArrowRightIcon
                sx={{
                  color: "#febd2f",
                  lineHeight: "1.5rem",
                  fontSize: "2.5rem",
                }}
              />
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Additional components */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 auto",
          maxWidth: "1123px",
          pt: 10,
        }}
      >
        <Grid lg={6}>
          <Box
            sx={{
              lineHeight: "1.2",
              fontWeight: 500,
              marginBottom: "1rem",
              fontSize: "3rem",
            }}
          >
            Best Seller
          </Box>
        </Grid>
        <Grid lg={6}>
          <Button
            as={Link} to="/categories"
            sx={{
              backgroundColor: "#febd2f",
              textDecoration:"none",
              color: "#173334",
              padding: "10px 20px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "0",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
                boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
              },
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <BrushIcon /> Show Products
          </Button>
        </Grid>
      </Box>

      {/* <AgriSlider/> */}
      <BestSellerCard />

      <MessageComponent />
      <Delivery />
    </>
  );
};

export default Home;
